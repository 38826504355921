import './App.css';
import React, { useState, useEffect} from 'react';

import {Stack, Typography, InputAdornment, Button} from '@mui/material';

import CalculateIcon from '@mui/icons-material/Calculate';
import TextField from '@mui/material/TextField';

function App() {
  
  const [minLargo, setMinLargo] = useState();
  const [maxLargo, setMaxLargo] = useState();

  
  const calculateButtonOnClick = (largo, ancho) => {
    calcularCalzado(largo, ancho);
  };

  // Iniciar servicio
  const calcularCalzado = async (largo, ancho) => {
    try {
    } catch (error) {
      console.error('Error calculando los datos', error);
    }
  };

  return (
    <Stack 
    direction={{ xs: 'column', sm: 'column' }}
    spacing={{ xs: 1, sm: 2, md: 4 }} 
    className='body'> 
    <div className="App">
      <header className="App-header">
        <Typography variant="h3" className='titleClass'>PIES Y ZAPATOS</Typography>
      </header>
      <body>
      <Stack 
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }} 
        className='StackClass'>
            <TextField
              label="Largo de pie"
              id="outlined-start-adornment"
              sx={{ m: 2, width: '25ch' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">cm</InputAdornment>,
              }}
            />
            <TextField
              label="Ancho de pie"
              id="outlined-start-adornment"
              sx={{ m: 4, width: '25ch' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">cm</InputAdornment>,
              }}
            />
            <Button variant="contained" startIcon={<CalculateIcon onClick={()=>calculateButtonOnClick(1, 2)}/>}>¡BUSCA MI TALLA!</Button>

        </Stack>
      </body>
    </div>
    </Stack>
  );
}

export default App;
